<template>
  <div>
    <div v-if="!editing" class="font-bold pb-2">{{ title }}</div>
    <div
      v-if="!editing && info"
      class="italic -mt-1 px-2 py-1 mb-2 rounded-lg text-sm bg-yellow-lightest"
    >
      {{ info }}
    </div>

    <textarea
      id="id"
      :name="id"
      @input="input"
      @blur="blur"
      v-model="textanswer"
      class="rounded-t rounded-l rounded-r border border-grey-light px-2 py-1 text-left text-xl w-full"
      :maxlength="maxChars"
    ></textarea>
    <div class="flex justify-end">
      {{ currentChars }} / {{ maxChars }}
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseTextarea",
  props: {
    formId: String,
    id: Number,
    title: String,
    answer: String,
    editing: {
      type: Boolean,
      default: false,
    },
    info: {
      type: String,
      default: null,
    },
    maxChars: {
      type: Number,
      default: 1000
    }
  },
  data() {
    return {
      textanswer: this.answer,
    };
  },
  watch: {
    textanswer: {
      immediate: false,
      handler(value) {
        this.$emit("disableNext", value != "" ? false : true);
      },
    },
  },
  computed: {
    currentChars() {
      return this.textanswer ? this.textanswer.length : 0;
    }
  },
  methods: {
    blur(evt) {
      let value = this.sanitize(evt.target.value);
      this.textanswer = value;

      this.$store
        .dispatch("answer", {
          formId: this.formId,
          id: this.id,
          value: value,
          type: "open",
          label: "",
        })
        .then(() => {
          this.$emit("disableNext", value != "" ? false : true);
        });
    },
    input(evt) {
      let value = evt.target.value;
      this.textanswer = this.sanitize(value);
    },
    sanitize(value) {
      value = value.replace(/\p{Emoji_Presentation}/gu, '');
      return value.slice(0, this.maxChars);
    }
  },
};
</script>
